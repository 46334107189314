
.ionContent {
  --background: none;
}

.phoneContainer {
  width: 100%;
}

.phoneWrapper {
  max-width: 300px;
  margin: 0 auto;
  width: 70%;
}

.phoneOutput {
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 2em;
  /* color: rgb(0, 122, 254); */
  white-space: pre-wrap;
  max-width: 100%;
}

@media (max-width: 500px) {
  .phoneOutput {
    font-size: 1.5em;
  }
}

.overlay {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btns .btn:only-child {
  margin-left: auto;
}

