
.surface {
  position: absolute;
  left: 0;
  top: -100px;
  right: 0;
  bottom: -100px;
}

.ionContent {
  --background: none;
  opacity:1;
}

.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.ionCardTitle p {
  margin: 0;
}

.ionCardTitle {
  white-space: pre-wrap;
}

.ionCardTitle code {
  font-family: inherit!important;
}

.overlay {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay .overlayElement {
  pointer-events: all;
  background-color: #e4e4e4!important;
  box-shadow: none!important;
}

.spinner {
  margin: auto;
}

.page {
  display: block;
}


