.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.col {
  flex-basis: 33.3333%;
  padding-top: 33.3333%;
  position: relative;
  min-height: 1px;
}

.backspaceCol {
  flex-basis: 33.3333%;
  padding-top: 33.3333%;
  position: relative;
  min-height: 1px;
  background: transparent;
}

.btn {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  display: block;
  width: calc(100% - 10px);
  border-radius: 50%;
  background-color: #ccc;
  outline: none;
  margin: auto;
}

.backspaceFill {
  fill: #ccc;
}

.backspaceCrossFill {
  fill: #000;
}

.btn:active {
  background-color: #aaa;
}

.backspaceBtn:active .backspaceFill {
  fill: #aaa;
}

.character {
  white-space: nowrap;
  display: inline-block;
}

.subCharacter {
  white-space: pre-wrap;
  display: inline-block;
}

.root {
  user-select: none;
}
/* 
body:global(.dark) .btn {
  background-color: #888;
}

body:global(.dark) .backspaceFill {
  fill: #888;
}

body:global(.dark) .btn:active {
  background-color: #ccc;
} */

.backspaceBtn {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 5px;
  top: 5px;
  display: block;
  width: calc(100% - 10px);
  border-radius: 50%;
  outline: none;
  background: transparent;
  padding: 15px;
}

@media screen and (max-width: 600px), (max-height: 600px) {
  .backspaceBtn {
    padding: 10px;
  }
}

@media screen and (max-width: 500px), (max-height: 600px) {
  .backspaceBtn {
    padding: 5px;
  }
}


.backspaceBtn .character {
  height: 100%;
}
