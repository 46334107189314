
.root {
  justify-content: space-between;
}

.btn {
  transition: 1000ms ease;
}

.btn.hidden {
  opacity: 0;
  pointer-events: none;
}

.root .btn:only-child {
  margin-left: auto;
  margin-right: auto;
}
