.ionContent {
  --background: none;
  opacity:1;
}

.phoneContainer {
  width: 100%;
}

.phoneWrapper {
  max-width: 300px;
  margin: 0 auto;
  width: 70%;
}

.overlay {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btns {
  display: flex;
  justify-content: space-between;
}

.btns .btn:only-child {
  margin-left: auto;
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex-grow: 1;
}

.ionCardContent {
  display: flex;
}
