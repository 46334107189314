
.surface {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.ionContent {
  --background: none;
  opacity:1;
}

