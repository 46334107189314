
.Overlay {
    position: relative;
    transition: min-height 500ms ease 1s;
}

.overlayPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.5);
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

/* body:global(.dark) .overlayPanel {
  background: #444444;
  box-shadow: 0 0 5px 5px #444444;
} */

@keyframes fadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  99% {
    opacity: 0;
    transform: scale(0.8);
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    transform: scale(0.8);
  }
}

.overlayPanel.active {
  animation: fadeIn 1s ease;
  opacity: 1;
  display: flex;
}

.overlayPanel:not(.active) {
  animation: fadeOut 1s ease 50ms /* 50ms is for allowing the browser to render the layout in time */;
  opacity: 0;
  display: none;
}
