

.container {
  text-align: left;
  max-width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 450px) {
  .container {
    padding: 0 10px;
  }
}

.container :global(.workflow-card) {
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  display: flex;
  flex-shrink: 0;
  margin-top: auto;
  min-height: 600px;
}

.container :global(.workflow-card) ion-card-header {
  padding: 15px;
}

.container :global(.workflow-card) ion-card-content {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 15px;
  overflow: visible;
  width: 100%;
}

.container :global(.workflow-card) ion-card-content p {
  font-size: inherit;
}

.container a {
  text-decoration: none;
}

.toolbar {
  --background: transparent;
}

:global(.card-footer) {
  text-align: center;
  position: relative;
  display: block;
  z-index: 1;
  font-size: 16px;
  padding: 0 20px 20px 20px;
  min-height: 100px;
  margin-bottom: auto;
}


@media screen and (max-height: 900px) {
  .container :global(.workflow-card) {
    min-height: calc(100vh - 200px);
  }
}

@media screen and (max-height: 800px) {
  .container :global(.workflow-card) {
    min-height: calc(100vh - 200px);
  }
}

@media screen and (max-height: 700px) {
  .container :global(.workflow-card) {
    min-height: calc(100vh - 200px);
  }
}

@media screen and (max-height: 600px) {
  .container :global(.workflow-card) {
    min-height: calc(100vh - 200px);
  }
}

@media screen and (max-height: 500px) {
  .container :global(.workflow-card) {
    min-height: calc(100vh - 200px);
  }
}