:root ion-card {
  --border-radius: 10px;
  border-radius: 10px;
}

:root ion-button {
  --border-radius: 10px;
  border-radius: 10px;
}

:global(.background-surface) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

ion-card,
ion-list,
ion-list.list-ios,
ion-item {
  --background-activated: none;
  --background-focused: none;
  --background-hover: none;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
}
/* 
@media (prefers-color-scheme: dark) {
  ion-card,
  ion-list,
  ion-list.list-ios,
  ion-item {
    --ion-background-color: #444444 !important;
    --background: #444444 !important;
    --color: #ccc;
    background: #444444 !important;
  }

  ion-item {
    --border-color: #999;
  }
} */

/* Fallback for older browsers or manual mode */
/* body:global(.dark) ion-card,
body:global(.dark) ion-list,
body:global(.dark) ion-list.list-ios,
body:global(.dark) ion-item {
  --ion-background-color: #444444 !important;
  --background: #444444 !important;
  background: #444444 !important;
  --color: #ccc;
} */

/* ion-router-outlet > :global(.ion-page) {
  display: none;
}

ion-router-outlet > :global(.ion-page):not(.ion-page-invisible):nth-child(1) {
  display: block;
}

ion-router-outlet > :global(.ion-page):not(.ion-page-invisible):nth-child(2) {
  display: block;
} */


:global(.grecaptcha-badge) {
  bottom: 140px !important;
}

ion-button {
  white-space: pre-wrap;
}

:global(.ion-flex) {
  display: flex;
}

:global(.ion-flex-col) {
  flex-direction: column;
}

:global(.ion-flex-row) {
  flex-direction: row;
}

:global(.ion-full-height) {
  height: 100%;
}

:global(.ion-full-width) {
  width: 100%;
}

:global(.update-service-worker-update-toast) {
  --end: 0px; 
}

body {
  touch-action: manipulation;
}

ion-input, ion-textarea {
  --background: var(--ion-color-contrast)!important;
  border-radius: 10px;
  --padding-start: 8px!important;
}

ion-button.ion-color-white.button-solid:not(.in-toolbar) {
  --background: var(--ion-color-white)!important;
  --ion-color-base: var(--ion-color-white)!important;
  --ion-color-contrast: var(--ion-color-white-contrast) !important;
}

ion-button.button-solid:not(.in-toolbar) {
  --box-shadow: 0 0 5px rgba(var(--ion-color-dark-rgb), 0.2);;
}

.big-sur-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(49,113,224);
  background: linear-gradient(49deg, rgb(224, 224, 224) 0%, rgb(246, 246, 246) 100%);
}

.big-sur-dark-background {
  background: rgb(17,38,75);
  background: linear-gradient(49deg, rgba(17,38,75,1) 0%, rgba(0,1,1,1) 100%);
}

:global(.card-content-ios) {
  line-height: 1.4em;
}

:global(.card-content-ios) * {
  visibility: visible!important;
}

:global(.workflow-content-main) * {
  visibility: visible!important;
  transform: translateZ(0); 
}

:global(.workflow-card-content) * {
  visibility: visible!important;
  transform: translateZ(0); 
}

